<!--
 * @Description:周边泊位 aroundParkSpace
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 01:01:14
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="周边泊位" />
    <div class="address"
         @click="clickMyAddress">
      <span>当前位置: </span>
      <van-image :src="require('@/assets/wxAroundParkSpace/ditu.png')"></van-image>
      <span>{{myAddres.myaddress}}</span>
    </div>
    <div class="navbar-box">
      <div class="navbar-item"
           @click="barChange(2)">
        <span :class="isActive===2? 'active-item':'default-item'">附近停车场</span>
      </div>
      <div class="navbar-item"
           @click="barChange(1)">
        <span :class="isActive===1? 'active-item':'default-item'">附近路段泊位</span>
      </div>
    </div>
    <div class="list-box">
      <van-list v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                :immediate-check="false"
                @load="onLoad(2)">
        <div class="list-item"
             v-for="item in parkList"
             :key="item.id">
          <card :item="item"
                @clickNavigation="clickNavigation"
                @clickDetail="toPage('/aroundParkInfo',item)" />
        </div>
      </van-list>
    </div>
    <van-popup v-model="showMap"
               position="bottom"
               :style="{ height: '90%',width:'100%' }">
      <van-nav-bar title="选择地点"
                   left-text="取消"
                   @click-left="showMap=false"
                   @click-right="selectAddress">
        <template #right>
          <span style="color:#5592FA;">确定</span>
        </template>
      </van-nav-bar>
      <van-field v-model="chooesAddress.myaddress"
                 label="你的位置："
                 readonly />
      <iframe id="chooseMap"
              :src="`https://m.amap.com/picker/?zoom=15&center=${myAddres.mylongitude},${myAddres.mylatitude}&radius=1000&total=20&key=f3b01243929a23cf9b3ba2deeb49fc4b`"
              style="height:calc(100% - 118px);width:100%;border:0px;"></iframe>
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import card from './components/card.vue'
import currentPosition from '@/utils/currentPosition.js'
import getUserInfo from '@/utils/getUserInfo.js'
import wx from 'weixin-jsapi'
import $wxConfig from '@/utils/wxConfig.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    card
  },
  data () {
    // 这里存放数据
    return {
      // navbar激活 1.路内 2.路外
      isActive: 2,
      // 列表绑定数据
      loading: true,
      finished: false,
      error: false,
      parkList: [],
      myAddres: {
        mylatitude: 0.0, // 当前经度
        mylongitude: 0.0, // 当前纬度
        myaddress: '正在获取位置...'
      },
      showMap: false,
      chooesAddress: {
        mylatitude: 0.0,
        mylongitude: 0.0,
        myaddress: '正在获取位置...'
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.getlonlat()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 创建地图
    initMap () {
      this.$nextTick(() => {
        const iframe = document.getElementById('chooseMap').contentWindow
        // 监听 chooseMap 创建完成
        document.getElementById('chooseMap').onload = function (e) {
          // iframe 其他窗口的引用，如 iframe的contentWindow、执行window.open返回的窗口对象、或者是命名过或数值索引的window.frames。
          /* 参数
          （ 将要发送的消息，
            指定那些窗口能接收到消息事件，其值可以是字符串 “*” 表示无限制，或者是一个URI。
            是一串和message同时传递的Transferable对象，这些对象的所有权将被转移给消息的接收方，而发送方将不再保留所有权。）
          */
          iframe.postMessage('hello', 'https://m.amap.com/picker/')
        }
      })
      const self = this
      window.addEventListener('message', function (e) {
        if (e.data.address && e.data.location && e.data.name) {
          self.chooesAddress.myaddress = e.data.name
          const lonLat = e.data.location.split(',')
          self.chooesAddress.mylongitude = Number(lonLat[0])
          self.chooesAddress.mylatitude = Number(lonLat[1])
        }
      }, false)
    },
    // 地点选择确认按钮
    selectAddress () {
      this.myAddres = this.$deepClone(this.chooesAddress)
      this.showMap = false
      this.onLoad(2)
    },
    // 获取经纬度
    getlonlat () {
      currentPosition(complete => {
        this.myAddres.mylongitude = complete.position.lng
        this.myAddres.mylatitude = complete.position.lat
        this.myAddres.myaddress = complete.formattedAddress
        this.onLoad(2)
      }, error => {
        this.$toast('位置获取失败')
        console.log(error)
      })
    },
    onLoad (parkTypeCode) {
      const info = {
        longitude: this.myAddres.mylongitude,
        latitude: this.myAddres.mylatitude
      }
      this.parkList = []
      this.finished = false
      this.$aroundParkSpace.parkNearByNoLimit(info).then(res => {
        // 1.路内 2.路外
        res.resultEntity.forEach(item => {
          if (item.parkTypeCode === parkTypeCode) {
            this.parkList.push(item)
          }
        })
        this.finished = true
      })
    },
    // 点击当前位置
    clickMyAddress () {
      if (this.myAddres.mylongitude !== 0.0 && this.myAddres.mylatitude !== 0.0) {
        this.chooesAddress = this.$deepClone(this.myAddres)
        this.showMap = true
        this.initMap()
      } else {
        this.chooesAddress = this.$deepClone(this.myAddres)
        this.showMap = true
        this.initMap()
        this.$toast('正在获取当前位置...')
      }
    },

    barChange (parkTypeCode) {
      this.isActive = parkTypeCode
      this.onLoad(parkTypeCode)
    },
    // 点击导航
    clickNavigation (item) {
      // 首先配置微信 config, 参数 jsApiList
      $wxConfig(['openLocation']).then(() => {
        // 配置完成后调用导航方法
        wx.openLocation({
          latitude: Number(item.latitude), // 纬度，浮点数，范围为90 ~ -90
          longitude: Number(item.longitude), // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 18, // 地图缩放级别,整形值,范围从1~28。默认为最大
          name: item.address // 位置名
        })
      })
    },
    // 页面跳转
    toPage (path, query) {
      this.$emit('clickDetail', this.item)
      this.$router.push({ path, query })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .address {
    width: 100%;
    height: 40px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    line-height: 40px;
    font-size: 15px;
    font-style: PingFang-SC-Regular;
    font-weight: Regular;
    background: #ffffff;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .van-image {
      width: 10px;
      margin: 0 3px 0 10px;
    }
  }
  .navbar-box {
    display: flex;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-top: solid 1px #ebebeb;
    background: #ffffff;
    .navbar-item {
      flex-grow: 1;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      box-sizing: border-box;
      line-height: 30px;
      .active-item {
        color: #19a9fc;
        border-bottom: solid 3px #19a9fc;
      }
      .default-item {
        border-bottom: solid 3px #ffffff;
      }
    }
  }
  .list-box {
    width: 100%;
    height: calc(100% - 142px);
    overflow: auto;
    .list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }
}
</style>
