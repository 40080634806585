<!--
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-09 16:00:19
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>
    <div class="left">
      <div class="parking">{{item.parkName}}</div>
      <div class="row2">
        <span class="row2-1">车位:<span class="prominent">{{item.emptySpace}}</span> 个</span>
        <span>距离:<span class="prominent">{{getDistance[0]}}</span>{{getDistance[1]}}</span>
      </div>
      <div class="row3">
        <van-image :src="require('@/assets/wxAroundParkSpace/ditusmall.png')"></van-image>
        <span>{{item.address}}</span>
      </div>
      <div class="detail"
           @click="clickDetail">
        查看详情
        <van-image :src="require('@/assets/wxAroundParkSpace/xiangqing.png')"></van-image>
      </div>
    </div>
    <div class="right"
         @click="clickNavigation">
      <van-image :src="require('@/assets/wxAroundParkSpace/daohang.png')"></van-image>
      <div>导航</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    // 这里存放数据
    return {
    }
  },
  // 监听属性 类似于data概念
  computed: {
    getDistance () {
      if (Number(this.item.distance) < 1) {
        return [(Number(this.item.distance) * 1000).toFixed(0), '米']
      }
      return [Number(this.item.distance).toFixed(3), '千米']
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    clickNavigation () {
      this.$emit('clickNavigation', this.item)
    },
    clickDetail () {
      this.$emit('clickDetail', this.item)
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  display: flex;
  width: 95%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;

  .left {
    flex-grow: 8;
    display: flex;
    flex-direction: column;
    width: 80%;
    .prominent {
      color: #19a9fc;
    }
    .parking {
      width: 95%;
      font-size: 17px;
      font-weight: 600;
      line-height: 30px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .row2 {
      font-size: 15px;
      line-height: 25px;
      color: #444444;
      .row2-1 {
        margin-right: 10px;
      }
    }
    .row3 {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 25px;
      color: #444444;
      span {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .van-image {
        width: 10px;
        margin-right: 5px;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 25px;
      color: #19a9fc;
      .van-image {
        width: 6px;
        margin-left: 10px;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    line-height: 33px;
    font-size: 15px;
    color: #333333;
    border-left: solid 1px #e7e8e8;
    .van-image {
      width: 25px;
    }
  }
}
</style>
